import GATSBY_COMPILED_MDX from "/opt/build/repo/content/blog/hello-world/index.md";
import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import {Link} from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {PostImage} from '../ui';
import {rhythm, scale} from '../utils/typography';
import {jsx as ___EmotionJSX} from "@emotion/react";
var BlogPostTemplate = function BlogPostTemplate(_ref) {
  var data = _ref.data, pageContext = _ref.pageContext, location = _ref.location, children = _ref.children;
  var post = data.mdx;
  var frontmatter = post.frontmatter;
  var siteTitle = data.site.siteMetadata.title;
  var previous = pageContext.previous, next = pageContext.next;
  return ___EmotionJSX(Layout, {
    location: location,
    title: siteTitle
  }, ___EmotionJSX(SEO, {
    title: frontmatter.title,
    description: frontmatter.description || post.excerpt
  }), ___EmotionJSX("article", null, ___EmotionJSX("header", null, ___EmotionJSX("h1", {
    style: {
      marginTop: rhythm(1),
      marginBottom: 0
    }
  }, frontmatter.title), ___EmotionJSX("p", {
    style: Object.assign({}, scale(-1 / 5), {
      display: "block",
      marginBottom: rhythm(1)
    })
  }, frontmatter.date)), frontmatter.cover && ___EmotionJSX(PostImage, _extends({}, frontmatter.cover.src.childImageSharp, {
    alt: frontmatter.title,
    caption: frontmatter.cover.author && ___EmotionJSX(React.Fragment, null, "Photo by", ' ', frontmatter.cover.authorUrl ? ___EmotionJSX("a", {
      rel: "nofollow",
      href: frontmatter.cover.authorUrl
    }, frontmatter.cover.author) : frontmatter.cover.author)
  })), children, ___EmotionJSX("hr", {
    style: {
      marginBottom: rhythm(1)
    }
  }), ___EmotionJSX("footer", null)), ___EmotionJSX("nav", null, ___EmotionJSX("ul", {
    style: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      listStyle: "none",
      padding: 0
    }
  }, ___EmotionJSX("li", null, previous && ___EmotionJSX(Link, {
    to: previous.fields.slug,
    rel: "prev"
  }, "\u2190 ", previous.frontmatter.title)), ___EmotionJSX("li", null, next && ___EmotionJSX(Link, {
    to: next.fields.slug,
    rel: "next"
  }, next.frontmatter.title, " \u2192")))));
};
BlogPostTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPostTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
var pageQuery = "3340206586";
