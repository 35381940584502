/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Привет мир!"), "\n", React.createElement(_components.p, null, "Меня зовут Святослав и в этот раз я решил создать свой блог. Этот блог будет\nпосвящен… абсолютно всему, что мне интересно. Да, преимущественно я планирую\nписать о программировании, JavaScript и смежных темах, но и не буду себя\nсдерживать, если мне захочется написать о чем-то еще, что мне захочется\nувековечить в интернетах!"), "\n", React.createElement(_components.p, null, "Надеюсь, этот сайт будет полезен не только для меня :)"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
